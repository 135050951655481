<template>
    <div>
        <b-container class="mt-5 mb-5">
            <template v-if="!form.canceled_at && form.published_at">
                <b-dropdown dropleft variant="outline-dark" text="Opties" class="float-right">
                    <b-dropdown-item variant="danger" @click="$bvModal.show('cancel-modal')">Evenement annuleren</b-dropdown-item>
                </b-dropdown>
            </template>
            <h1 class="fs-4 font-weight-bold mb-4">Bewerk evenement</h1>
            <EventCanceledAlert :event="form" />
            
            <ValidationObserver v-slot="{ invalid, validate }">
                <b-form @submit.prevent="validate().then($bvModal.show('edit-modal'))" autocomplete="off">
                    <EventDetails :form="form" />
                    <EventLocation :form="form" />
                    <EventDates :form="form" />
                    <EventSaleDate :form="form" />
                    <EventOptions :form="form" />
                    <EventMarketing :form="form" />
                    <template v-if="!form.canceled_at">
                        <div class="clearfix">
                            <b-button type="submit" variant="primary" :disabled="invalid || isSubmited" class="float-right">
                                Opslaan
                                <b-spinner small v-if="isSubmited" class="align-middle" />
                                <font-awesome-icon v-else icon="fa-solid fa-chevron-right" />
                            </b-button>
                        </div>
                    </template>
                </b-form>
            </ValidationObserver>
            <b-modal id="edit-modal" title="Evenement wijzigen" hide-header-close>
                <b-alert variant="warning" show>
                    Je staat op het punt dit evenement te wijzigen.
                </b-alert>
                <div class="mb-3">
                    <label>Reden voor wijziging <small>(optioneel)</small></label>
                    <VueTrix v-model="form.edit_message" />
                    <p class="text-muted">Dit bericht is zichtbaar voor bezoekers en wordt toegevoegd aan ons wijzigingsbericht.</p>
                </div>
                <template #modal-footer="{ close }">
                    <b-button variant="outline-secondary" @click="close()">Annuleren</b-button>
                    <b-button variant="primary" @click="submitForm();">Wijzigen</b-button>
                </template>
            </b-modal>
            <b-modal id="cancel-modal" title="Evenement annuleren" hide-header-close>
                <b-alert variant="warning" show>
                    Je staat op het punt dit evenement te annuleren. Dit is een <strong>permanente actie</strong> en kan <strong>niet meer ongedaan worden gemaakt</strong>.
                </b-alert>
                <div class="mb-3">
                    <label>Reden voor annulering <small>(optioneel)</small></label>
                    <VueTrix v-model="form.cancel_message" />
                    <p class="text-muted">Dit bericht is zichtbaar voor bezoekers en wordt toegevoegd aan ons annuleringsbericht.</p>
                </div>
                <template #modal-footer="{ close }">
                    <b-button variant="outline-secondary" @click="close()">Annuleren</b-button>
                    <b-button variant="danger" @click="cancelEvent();">Definitief annuleren</b-button>
                </template>
            </b-modal>
        </b-container>
    </div>
</template>
  
<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    import { ValidationObserver } from 'vee-validate';
    import EventCanceledAlert from '@/components/event/EventCanceledAlert'
    import EventDetails from '@/components/event/EventDetails'
    import EventLocation from '@/components/event/EventLocation'
    import EventSaleDate from '@/components/event/EventSaleDate'
    import EventDates from '@/components/event/EventDates'
    import EventOptions from '@/components/event/EventOptions'
    import EventMarketing from '@/components/event/EventMarketing'

    import VueTrix from "vue-trix"

    export default {
        metaInfo: {
            title: 'Bewerk evenement',
        },
        components: {
            ValidationObserver,
            EventCanceledAlert,
            EventDetails,
            EventLocation,
            EventSaleDate,
            EventDates,
            EventOptions,
            EventMarketing,
            VueTrix,
        },
        data() {
            return {
                isSubmited: false,
                form: {
                    cancel_message: null,
                    edit_message: null,
                    slots: []
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            cancelEvent: function() {
                if( confirm("Weet je zeker dat je dit evenement wilt annuleren?") ) {
                    this.$axios.post("https://api.tixgo.nl/events/cancel/" + this.$route.params.id, this.form)
                        .then( response => {
                            response;
                            this.$router.push('/manage/events');
                        })
                    ;
                }
            },
            deleteEvent: function() {
                if( confirm("Weet je zeker dat je dit evenement wilt annuleren?") ) {
                    this.$axios.delete("https://api.tixgo.nl/events/" + this.$route.params.id)
                        .then( response => {
                            response;
                            this.$router.push('/manage/events');
                        })
                    ;
                }
            },
            fetchEvent: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
                    .then( response => {
                        this.setLoading(false);
                        this.form = response.data;
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            submitForm() {
                this.isSubmited = true;

                if( confirm("Weet je zeker dat je dit evenement wilt wijzigen?") ) {
                    this.$axios.put("https://api.tixgo.nl/events/" + this.$route.params.id, this.form)
                        .then( response => {
                            response;
                            this.isSubmited = false;
                            this.$router.push('/manage/events');
                        })
                    ;
                }
            }
        },
        created() {
            this.fetchEvent();
        },
    }
</script>